.preloader {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.clear-loading {
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}
.loading-effect-2 {
    width: 100px;
    height: 100px;
}
.loading-effect-2 > span,
.loading-effect-2 > span:before,
.loading-effect-2 > span:after {
    content: "";
    display: block;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.loading-effect-2 > span {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-left-color: transparent;
    -webkit-animation: effect-2 2s infinite linear;
    -moz-animation: effect-2 2s infinite linear;
    -ms-animation: effect-2 2s infinite linear;
    -o-animation: effect-2 2s infinite linear;
    animation: effect-2 2s infinite linear;
}
.loading-effect-2 > span:before {
    width: 75%;
    height: 75%;
    border-right-color: transparent;
}
.loading-effect-2 > span:after {
    width: 50%;
    height: 50%;
    border-bottom-color: transparent;
}
@-webkit-keyframes effect-2 {
    from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes effect-2 {
    from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}